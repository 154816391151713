<style scoped>

</style>
<template>
    <div style="margin: 0px 10px;">
        <div class="row">
            <div class="col-md-12">
                <h2 class="pull-left">Nota</h2>
                <div class="pull-right" style="margin-top:20px;">
                <button type="button" class="close" aria-label="Close" @click="close()"><span aria-hidden="true">&times;</span></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form>
                    <input v-model="note.date" type="hidden" name="date"/>
                    <input v-model="note.ambulatory_id" type="hidden" name="ambulatory_id"/>

                    <div class="form-group">
                        <div class="row">
                        <label  class="col-sm-2 control-label"
                                for="doctor">Dottore:</label>
                        <div class="col-sm-10">
                            <select v-model="note.doctor_id" class="form-control" name="doctor_id" v-select2>
                                <option v-for="doctor in doctors" :key="'doctor_'+doctor.id" :value="doctor.id">{{doctor.name}}</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                        <label class="col-sm-2 control-label"
                            for="note" >Testo:</label>
                        <div class="col-sm-10">
                            <textarea v-model="note.text" class="form-control" rows="3" name="note"></textarea>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                        <div class="col-sm-offset-2 col-sm-10">

                            <div class="checkbox icheck">
                                <label>
                                    <input v-model="note.warning" type="checkbox" id="warning" name="warning" value="warning" icheck> Attenzione
                                </label>
                            </div>

                        </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-md-12 text-right">
                <button class="btn btn-success" @click="saveNote()">
                    <i class="fa fa-save" aria-hidden="true"></i> Salva
                </button>
                <button class="btn btn-danger" @click="deleteNote()" v-if="edit">
                    <i class="fa fa-save" aria-hidden="true"></i> Elimina
                </button>
                <button class="btn btn-primary" @click="close()">
                    <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                </button>
            </div>
        </div>
    </div>

</template>
 
<script>
    export default {
        props: {
            note: {
                type: Object,
                default: () => { return {
                    "id":null,
                    "ambulatory_id":null,
                    "date":null,
                    "text":null,
                    "created_at":null,
                    "updated_at":null,
                    "doctor_id":null,
                    "warning": 0,
                    "doctor":{}
                }},
            },
            doctors: Array,
            ambulatory_id: Number,
            date: String,
            edit: Boolean,
        },
        mounted() {
            let vm = this;
            
            // Popolo la selezione dottori
            $("select[name='doctor_id']").select2();
            $('input').iCheck({
                checkboxClass: 'icheckbox_square-blue',
                radioClass: 'iradio_square-blue',
                increaseArea: '20%' // optional
            });
            $('input').on('ifChanged', function(e){ 
                $(this).val(e.target.checked? 1 : 0); 
                vm.note.warning = e.target.checked? 1 : 0;
            });

            this.note.ambulatory_id = this.ambulatory_id;
            this.note.date = this.date;
        },
        data: function () {
            return {
            }
        },
        methods: {
            close(){
                this.$emit('close', true);
            },
            deleteNote(){
                let vm = this;
                bootbox.confirm("Vuoi confermare l'eliminazione?", function(result) {
                    console.log(result);
                    if (result) {
                        axios.delete('/notes/'+vm.note.id)
                            .then(res => {
                                console.log(res)
                                vm.$parent.$emit('update');
                                vm.close();
                            }).catch(err => {
                                console.log(err)
                            });
                    }
                });
            },
            saveNote(){
                if(!this.edit){
                    console.log("creating note");
                } else {
                    console.log("saving note");
                }
                
                let vm = this;
                const params = new URLSearchParams();
                params.append('date', this.note.date);
                params.append('doctor_id', this.note.doctor_id);
                params.append('ambulatory_id', this.note.ambulatory_id);
                params.append('text', this.note.text);
                params.append('warning', this.note.warning);
                if(!this.edit){
                    axios.post('/notes', params,{
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                        .then(res => {
                            console.log(res)
                            vm.$parent.$emit('update');
                            vm.close();
                        }).catch(err => {
                            console.log(err)
                        });
                } else {
                    axios.put('/notes/'+this.note.id, params,{
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                        .then(res => {
                            console.log(res)
                            vm.$parent.$emit('update');
                            vm.close();
                        }).catch(err => {
                            console.log(err)
                        });
                }
                
            },
        },
    }
</script>