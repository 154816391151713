<style scoped>
</style>
<template>
    <div style="margin: 0px 10px;">
        <div class="row">
            <div class="col-md-12">
                <h2 class="pull-left">Appuntamento</h2>
                <div class="pull-right" style="margin-top:20px;">
                <button type="button" class="close" aria-label="Close" @click="close()"><span aria-hidden="true">&times;</span></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form>
                    <input type="hidden" name="color"/>
                    <input type="hidden" name="doctor_id"/>
                    <input type="hidden" name="ambulatory_id"/>
                    <input type="hidden" name="client_id"/>
                    <div class="form-group">
                        <div class="row">
                        <label  class="col-xs-2 control-label"
                                for="doctor">Dottore:</label>
                        <div class="col-xs-10">
                            <input class="form-control" type="text" name="doctor" :value="appointment.objAppointment.doctor.name" readonly/>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                        <label class="col-xs-2 control-label" for="time_start">Inizio:</label>
                        <div class="col-xs-4">
                            <div class="input-group bootstrap-timepicker timepicker">
                                <input v-model="appointment.objAppointment.time_start" class="form-control" type="time" name="time_start" data-mask="99:99"/>
                                <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-time"></span>
                                </span>
                            </div>
                        </div>
                        <label class="col-xs-2 control-label" for="date">Data:</label>
                        <div class="col-xs-4">
                            <div class="input-group bootstrap-timepicker timepicker">
                                <input :value="appointment.objAppointment.date" class="form-control" type="text" name="date" readonly/>
                                <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                        <label class="col-xs-2 control-label"
                            for="patient" >Paziente:</label>
                        <div class="col-xs-10">
                            <input v-model="appointment.objAppointment.patient" class="form-control" type="text" name="patient" placeholder="Nome paziente"  :readonly="!canWrite"/>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                        <label class="col-xs-2 control-label"
                            for="telephone" >Telefono:</label>
                        <div class="col-xs-10">
                            <input v-model="appointment.objAppointment.telephone" class="form-control" type="text" name="telephone" placeholder="Numero di telefono"  :readonly="!canWrite"/>
                            
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                        <label class="col-xs-2 control-label"
                            for="note" >Note:</label>
                        <div class="col-xs-10">
                            <textarea v-model="appointment.objAppointment.note" class="form-control" rows="3" name="note" :readonly="!canWrite"></textarea>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                        <div class="col-xs-offset-2 col-xs-10">

                            <div class="checkbox icheck">
                                <label>
                                    <input v-model="appointment.objAppointment.warning" type="checkbox" name="warning" :style="{'pointer-events': !canWrite ? 'none' : ''}" :readonly="!canWrite"> Attenzione
                                </label>
                            </div>

                        </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;" v-if="canWrite">
            <div class="col-md-12 text-right">
                <button class="btn btn-primary" @click="clone(true)" :disabled="!appointment.objAppointment.patient" v-if="appointment.cloning">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Annulla
                </button>
                <button class="btn btn-primary" @click="clone()" :disabled="!appointment.objAppointment.patient||appointment.mooving||action" v-else>
                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Fissa nuovo appuntamento
                </button>
                <button class="btn btn-warning" @click="move(true)" :disabled="!appointment.objAppointment.patient" v-if="appointment.mooving">
                    <i class="fa fa-arrows" aria-hidden="true"></i> Annulla
                </button>
                <button class="btn btn-warning" @click="move()" :disabled="!appointment.objAppointment.patient||appointment.cloning||action" v-else>
                    <i class="fa fa-arrows" aria-hidden="true"></i> Sposta
                </button>
                <button class="btn btn-danger" @click="deleteApp()" :disabled="!appointment.objAppointment.patient">
                    <i class="fa fa-trash" aria-hidden="true"></i> Elimina appuntamento
                </button>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-md-12 text-right">
                <button class="btn btn-success" @click="save()" :disabled="!canWrite">
                    <i class="fa fa-save" aria-hidden="true"></i> Salva modifiche
                </button>
                <button class="btn btn-danger" @click="deleteAvail()" :disabled="!canWrite">
                    <i class="fa fa-trash" aria-hidden="true"></i> <i class="fa fa-medkit" aria-hidden="true"></i> 
                    Elimina disponibilità
                </button>
                <button class="btn btn-primary" @click="close()">
                    <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                </button>
            </div>
        </div>
    </div>

</template>
 
<script>
    export default {
        props: {
            appointment: Object,
            buffer: Object,
            action: String,
            canWrite: Boolean,
        },
        mounted() {
            let vm = this;

            // Make a backup for rollback
            this.bakObj = JSON.parse(JSON.stringify(this.appointment.objAppointment));
            console.log(this.bakObj);

            // Start move/clone action
            if(!this.appointment.objAppointment.patient){
                console.log(this.appointment);
                switch(this.action){
                    case 'move':
                        this.appointment.objAppointment.patient = this.buffer.objAppointment.patient;
                        this.appointment.objAppointment.telephone = this.buffer.objAppointment.telephone;
                        this.appointment.objAppointment.note = this.buffer.objAppointment.note;
                        this.appointment.objAppointment.warning = this.buffer.objAppointment.warning;
                        break;
                    case 'clone':
                        this.appointment.objAppointment.patient = this.buffer.objAppointment.patient;
                        this.appointment.objAppointment.telephone = this.buffer.objAppointment.telephone;
                        this.appointment.objAppointment.note = this.buffer.objAppointment.note;
                        this.appointment.objAppointment.warning = this.buffer.objAppointment.warning;
                        break;
                }
            }

            $('input').iCheck({
                checkboxClass: 'icheckbox_square-blue',
                radioClass: 'iradio_square-blue',
                increaseArea: '20%' // optional
            });
            $('input').on('ifChanged', function(e){ 
                $(this).val(e.target.checked? 1 : 0); 
                vm.appointment.objAppointment.warning = e.target.checked? 1 : 0;
            });

            
        },
        data: function () {
            return {
                bakObj: null,
            }
        },
        methods: {
            close(){
                this.appointment.objAppointment = JSON.parse(JSON.stringify(this.bakObj));
                this.$emit('close', true);
            },
            save(){
                let vm = this;
                axios.put('/appointments/'+this.appointment.id, {
                        color: this.appointment.objAppointment.color,
                        doctor_id: this.appointment.objAppointment.doctor_id,
                        ambulatory_id: this.appointment.objAppointment.ambulatory_id,
                        date: this.appointment.objAppointment.date,
                        time_start: this.appointment.objAppointment.time_start,
                        time_stop: this.appointment.objAppointment.time_stop,
                        note: this.appointment.objAppointment.note,
                        patient: this.appointment.objAppointment.patient,
                        telephone: this.appointment.objAppointment.telephone,
                        warning: this.appointment.objAppointment.warning,
                    })
                    .then(res => {
                        console.log(res)
                        vm.$parent.$emit('update');
                        vm.$emit('close', true);
                    }).catch(err => {
                        console.log(err)
                    });

                switch(this.action){
                    case 'move':
                        // Clear old appointment
                        axios.put('/appointments/'+this.buffer.id, {
                            date: this.buffer.objAppointment.date,
                            color: this.buffer.objAppointment.color,
                            doctor_id: this.buffer.objAppointment.doctor_id,
                            ambulatory_id: this.buffer.objAppointment.ambulatory_id,
                            time_start: this.buffer.objAppointment.time_start,
                            time_stop: this.buffer.objAppointment.time_stop,
                            note: null,
                            patient: null,
                            telephone: null,
                            warning: null,
                        })
                        .then(res => {
                            console.log(res)
                            this.move(true);
                            vm.$parent.$emit('update');
                        }).catch(err => {
                            console.log(err)
                        });
                        
                        break;
                    case 'clone':
                        this.clone(true);
                        vm.$parent.$emit('update');
                        break;
            }
            },
            clone(abort){
                if(!abort){
                    this.$parent.$emit('clone', this.appointment, 'clone');
                    Vue.set(this.appointment, 'cloning', true);
                    this.$emit('close', true);
                } else {
                    Vue.delete(this.appointment, 'cloning');
                    this.$parent.$emit('clone', null, null);
                    this.$emit('close', true);
                }
            },
            move(abort){
                if(!abort){
                    Vue.set(this.appointment, 'mooving', true);
                    this.$parent.$emit('move', this.appointment, 'move');
                    this.$emit('close', true);
                } else {
                    Vue.delete(this.appointment, 'mooving');
                    this.$parent.$emit('move', null, null);
                    this.$emit('close', true);
                }
            },
            deleteApp(){
                let vm = this;
                bootbox.confirm("Vuoi confermare l'eliminazione?", function(result) {
                    console.log(result);
                    if (result) {
                        vm.appointment.objAppointment.note = null;
                        vm.appointment.objAppointment.patient = null;
                        vm.appointment.objAppointment.telephone = null;
                        vm.appointment.objAppointment.warning = null;
                        vm.save();
                    }
                });
                
            },
            deleteAvail(){
                let vm = this;
                bootbox.confirm("Vuoi confermare l'eliminazione?", function(result) {
                    console.log(result);
                    if (result) {
                        axios.delete('/appointments/'+vm.appointment.id)
                            .then(res => {
                                console.log(res)
                                vm.$parent.$emit('update');
                                vm.$emit('close', true);
                            }).catch(err => {
                                console.log(err)
                            });
                    }
                });
            },
        },
    }
</script>