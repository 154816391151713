/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

const moment = require('moment')
require('moment/locale/it')
Vue.use(require('vue-moment'), {
    moment
})

import VModal from 'vue-js-modal'
Vue.use(VModal, {
    defaultProps: {
       height: 'auto',
       draggable: true
    }
});

Vue.directive('select2', {
    inserted(el) {
        $(el).on('select2:select', () => {
            const event = new Event('change', { bubbles: true, cancelable: true });
            el.dispatchEvent(event);
        });

        $(el).on('select2:unselect', () => {
            const event = new Event('change', {bubbles: true, cancelable: true})
            el.dispatchEvent(event)
        })
    },
});

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { localize } from 'vee-validate';
import it from 'vee-validate/dist/locale/it.json';
localize(it);
import * as rules from 'vee-validate/dist/rules';
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('register-form', require('./components/auth/RegisterForm').default);
Vue.component('login-form', require('./components/auth/LoginForm').default);
Vue.component('email-reset-password-form', require('./components/auth/EmailResetPasswordForm').default);
Vue.component('reset-password-form', require('./components/auth/ResetPasswordForm').default);

Vue.component('calendario', require('./components/Calendario.vue').default)
Vue.component('ambulatorio', require('./components/Ambulatorio.vue').default)


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});