<style scoped>
/* local styles */
.active{
    padding: 5px 5px;
}
</style>
<template>
    <div>
        <!-- Appointments buffer -->
        <div class="alert alert-warning" v-if="buffer">
            <button type="button" class="close" aria-label="Close" @click="setBuffer(null, null)"><span aria-hidden="true">&times;</span></button>
            
            <strong v-if="action=='move'">Sto spostando l'appuntamento</strong> 
            <strong v-if="action=='clone'">Nuovo appuntamento</strong> 
             di {{buffer.objAppointment.patient}} del {{buffer.objAppointment.date}} dalle {{buffer.objAppointment.time_start}} alle {{buffer.objAppointment.time_stop}}
            
        </div>
        <div class="nav-tabs-custom">
        
        
            <ul class="nav nav-tabs">
                <li v-for="a in ambulatories" :key="'amb_'+a.id" :style="{'border-top-color': isActiveTab(a.id) ? a.color : 'transparent'}">
                    <a :href="'#amb_'+a.id" @click.prevent="setActiveTab(a.id)" aria-expanded="true" :style="{color:a.color, 'font-size': 'large', 'font-weight': 'bold'}">{{a.name}}</a>
                </li>
                
                <li class="pull-right">
                    <!-- Button to change on previous month -->
                    <button class="btn btn-box-tool" id="prev_month" @click.prevent="moveCalendar(true, -1)">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>
                    <!-- Button to change on previous week -->
                    <button class="btn btn-box-tool" id="prev_week" @click.prevent="moveCalendar(false, -1)">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    </button>

                    <span>{{startDate | moment("DD/MM/YYYY")}} - {{endDate | moment("DD/MM/YYYY")}}</span>

                    <!-- Button to change on next week -->
                    <button class="btn btn-box-tool" id="next_week" @click.prevent="moveCalendar(false, 1)">
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                    <!-- Button to change on next month -->
                    <button class="btn btn-box-tool" id="next_month" @click.prevent="moveCalendar(true, 1)">
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                </li>
            </ul>
            <div class="tab-content" style="padding:0px;">
                <div :class="{ active: isActiveTab(a.id), 'tab-pane': true }" :id="'#amb_'+a.id" v-for="a in ambulatories" :key="'tab_amb_'+a.id">
                    <ambulatorio :id="a.id" :color="a.color" :name="a.name"
                        :start="startDate | moment('YYYY-MM-DD')" 
                        :end="endDate | moment('YYYY-MM-DD')"
                        :doctors="doctors"
                        @clone="setBuffer" @move="setBuffer"
                        :buffer="buffer" :action="action"
                        @cloneOk="setBuffer(null,null)" @moveOk="setBuffer(null,null)"
                        :user="user"
                        >
                    </ambulatorio>
                </div>
                <!-- /.tab-pane -->
                
            </div>
            <!-- /.tab-content -->
        </div>
        <!-- nav-tabs-custom -->
    </div>
</template>
 
<script>
    export default {
        props: {
            ambulatories:{type: Array, default: []},
            doctors:{type: Array, default: []},
            user:{type: Object, default: {}},
        },
        mounted() {
            //console.log('Component mounted.')
        },
        data: function () {
            return {
                loading: false,
                activeTab: 1,
                startDate: this.$moment().startOf('week').toDate(),
                endDate: this.$moment().endOf('week').toDate(),
                buffer: null,
                action: null,
            }
        },
        methods: {
            isActiveTab(index){return index==this.activeTab;},
            setActiveTab(index){
                this.activeTab=index;
            },
            // month == true 
            moveCalendar(month, direction){
                // Move the month
                this.startDate = this.$moment(this.startDate).add(direction, month? 'M' : 'w').startOf('week').toDate();
                this.endDate = this.$moment(this.startDate).add(6, 'd').toDate();
            },
            setBuffer(event, action){
                console.log("setBuffer");
                this.action = action;
                this.buffer = event;
            },
        },
        computed: {
        },
    }
</script>